/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

// import "./layout.css"
import Sidebar from "./sidebar"
import TopNavigation from "./topnav"
import ContentWrapper from "./content-wrapper"
import Footer from "./footer"

import "./style.scss"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <div className="container body">
        <div className="main_container">
          <Sidebar />
          <TopNavigation />
          <ContentWrapper>{children}</ContentWrapper>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
