import classNames from "classnames"
import { Link } from "gatsby"
import React, { useState } from "react"

function SidebarMenuItem({ icon, title, submenus, href }) {
  const hasSubmenus = submenus && Array.isArray(submenus) && submenus.length > 0
  const [showSubmenu, setShowSubmenu] = useState(false)
  return (
    <li className={classNames({ active: showSubmenu })}>
      {href ? (
        <Link to={href} onClick={() => setShowSubmenu(!showSubmenu)}>
          {icon && <i className={`fa fa-${icon}`}></i>}
          {title}
          {hasSubmenus && <span className="fa fa-chevron-down"></span>}
        </Link>
      ) : (
        <a href={href} onClick={() => setShowSubmenu(!showSubmenu)}>
          {icon && <i className={`fa fa-${icon}`}></i>}
          {title}
          {hasSubmenus && <span className="fa fa-chevron-down"></span>}
        </a>
      )}
      {hasSubmenus && (
        <ul
          className="nav child_menu"
          style={{ display: showSubmenu ? "block" : "none" }}
        >
          {submenus.map((menu, key) => {
            if (
              menu.submenus &&
              Array.isArray(menu.submenus) &&
              menu.submenus.length > 0
            ) {
              return (
                <SidebarMenuItem
                  title={menu.title}
                  submenus={menu.submenus}
                  href={menu.link}
                  key={key}
                />
              )
            }
            return (
              <li key={key}>
                {menu.link ? (
                  <Link to={menu.link}>{menu.title}</Link>
                ) : (
                  <a href={menu.link}>{menu.title}</a>
                )}
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

export default SidebarMenuItem
