import React, { useState } from "react"
import useBodyClass from "../lib/hooks/use-body-class"

function TopNavigation() {
  const [showSidebar, setShowSidebar] = useState(true)
  const toggleBodyClass = e => {
    setShowSidebar(!showSidebar)
  }

  const bodyClass = showSidebar ? "nav-md" : "nav-sm"
  useBodyClass(bodyClass)

  return (
    <div className="top_nav">
      <div className="nav_menu" style={{ height: "55px" }}>
        <div className="nav toggle">
          <span
            role="button"
            tabIndex="0"
            id="menu_toggle"
            onClick={toggleBodyClass}
            onKeyDown={toggleBodyClass}
          >
            <i className="fa fa-bars"></i>
          </span>
        </div>
        <nav className="nav navbar-nav">
          <h4
            style={{
              display: "flex",
              height: "55px",
              alignItems: "center",
            }}
            className="d-none d-lg-flex"
          >
            Free conversion, calculation and data manipulation utilities
          </h4>
          <h4
            style={{
              display: "flex",
              height: "55px",
              alignItems: "center",
            }}
            className="d-lg-none"
          >
            Online Tools!
          </h4>
        </nav>
      </div>
    </div>
  )
}

export default TopNavigation
