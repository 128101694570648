import React from "react"

function Footer() {
  return (
    <footer>
      <div className="pull-right">
        Online Tools! - A product by{" "}
        <a href="https://nimishprabhu.com">Nimish Prabhu</a>
      </div>
      <div className="clearfix"></div>
    </footer>
  )
}

export default Footer
