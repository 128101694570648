import { Link } from "gatsby"
import React from "react"
import SidebarHeading from "./heading"
import SidebarMenuItem from "./item"
import SidebarMenu from "./menu"
import SidebarSection from "./section"

function Sidebar() {
  // const multiLevelSubmenus = [
  //   {
  //     title: "Level One",
  //     link: "l1.html",
  //   },
  //   {
  //     title: "Level One",
  //     submenus: [
  //       {
  //         title: "Level Two",
  //         link: "#level2_1",
  //       },
  //       {
  //         title: "Level Two",
  //         link: "#level2_2",
  //       },
  //     ],
  //   },
  // ]

  const decoderMenus = [
    {
      title: "Base64 Decoder",
      link: "/base64-decoder",
    },
    {
      title: "Base32 Decoder",
      link: "/base32-decoder",
    },
    {
      title: "URL Decoder",
      link: "/url-decoder",
    },
  ]

  const encoderMenus = [
    {
      title: "Base64 Encoder",
      link: "/base64-encoder",
    },
    {
      title: "Base32 Encoder",
      link: "/base32-encoder",
    },
    {
      title: "URL Encoder",
      link: "/url-encoder",
    },
  ]

  return (
    <div className="col-md-3 left_col">
      <div className="left_col scroll-view">
        <div className="navbar nav_title" style={{ border: 0 }}>
          <Link to="/" className="site_title">
            <i className="fa fa-paw"></i> <span>Online Tools!</span>
          </Link>
        </div>

        <div className="clearfix"></div>

        <br />

        <div
          id="sidebar-menu"
          className="main_menu_side hidden-print main_menu"
        >
          <SidebarSection>
            <SidebarHeading headerText="TOOLS CATEGORIES" />
            <SidebarMenu>
              <SidebarMenuItem
                title={"Decoders"}
                icon="code"
                submenus={decoderMenus}
              />
              <SidebarMenuItem
                title="Encoders"
                icon="code"
                submenus={encoderMenus}
              />
              {/* 
              <SidebarMenuItem
                title={"Multilevel"}
                icon="sitemap"
                submenus={multiLevelSubmenus}
              /> */}
            </SidebarMenu>
          </SidebarSection>
          {/* <div className="menu_section">
            <h3>Live On</h3>
            <ul className="nav side-menu">
              <li>
                <a href="#!">
                  <i className="fa fa-bug"></i> Additional Pages{" "}
                  <span className="fa fa-chevron-down"></span>
                </a>
                <ul className="nav child_menu">
                  <li>
                    <a href="e_commerce.html">E-commerce</a>
                  </li>
                  <li>
                    <a href="projects.html">Projects</a>
                  </li>
                  <li>
                    <a href="project_detail.html">Project Detail</a>
                  </li>
                  <li>
                    <a href="contacts.html">Contacts</a>
                  </li>
                  <li>
                    <a href="profile.html">Profile</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#!">
                  <i className="fa fa-windows"></i> Extras{" "}
                  <span className="fa fa-chevron-down"></span>
                </a>
                <ul className="nav child_menu">
                  <li>
                    <a href="page_403.html">403 Error</a>
                  </li>
                  <li>
                    <a href="page_404.html">404 Error</a>
                  </li>
                  <li>
                    <a href="page_500.html">500 Error</a>
                  </li>
                  <li>
                    <a href="plain_page.html">Plain Page</a>
                  </li>
                  <li>
                    <a href="login.html">Login Page</a>
                  </li>
                  <li>
                    <a href="pricing_tables.html">Pricing Tables</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#!">
                  <i className="fa fa-sitemap"></i> Multilevel Menu{" "}
                  <span className="fa fa-chevron-down"></span>
                </a>
                <ul className="nav child_menu">
                  <li>
                    <a href="#level1_1">Level One</a>
                  </li>
                  <li>
                    <a href="#!">
                      Level One<span className="fa fa-chevron-down"></span>
                    </a>
                    <ul className="nav child_menu">
                      <li className="sub_menu">
                        <a href="level2.html">Level Two</a>
                      </li>
                      <li>
                        <a href="#level2_1">Level Two</a>
                      </li>
                      <li>
                        <a href="#level2_2">Level Two</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#level1_2">Level One</a>
                  </li>
                </ul>
              </li>
              <SidebarMenuItem
                title="Landing Page"
                icon="laptop"
                href="https://google.com"
              />
              <li>
                <a href="#!">
                  <i className="fa fa-laptop"></i> Landing Page{" "}
                  <span className="label label-success pull-right">
                    Coming Soon
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        {/* <div className="sidebar-footer hidden-small">
          <a
            href="#!"
            data-toggle="tooltip"
            data-placement="top"
            title="Settings"
          >
            <span className="glyphicon glyphicon-cog" aria-hidden="true"></span>
          </a>
          <a
            href="#!"
            data-toggle="tooltip"
            data-placement="top"
            title="FullScreen"
          >
            <span
              className="glyphicon glyphicon-fullscreen"
              aria-hidden="true"
            ></span>
          </a>
          <a href="#!" data-toggle="tooltip" data-placement="top" title="Lock">
            <span
              className="glyphicon glyphicon-eye-close"
              aria-hidden="true"
            ></span>
          </a>
          <a
            data-toggle="tooltip"
            data-placement="top"
            title="Logout"
            href="login.html"
          >
            <span className="glyphicon glyphicon-off" aria-hidden="true"></span>
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default Sidebar
