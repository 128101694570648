import React from "react"

function ContentWrapper({ children }) {
  return (
    <div className="right_col" role="main" style={{ minHeight: "100vh" }}>
      {children}
    </div>
  )
}

export default ContentWrapper
